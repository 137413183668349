// PageView.js

import React from "react";
import { useParams } from "react-router-dom";
import SpiderChart from "./SpiderChart";
import TableView from "./TableView";
import TreeMapView from "./TreeMapView";
import ScoreTableView from "./ScoreTableView";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import NotesGrid from "./NotesGrid";
import LinksGrid from "./LinksGrid";

const PageView = ({
  pageData,
  chartSize,
  title,
  pageId,
  isHomePage,
  onDataPointClick,
  thirdPaneOpen,
  view,
  pages,
  heuristics,
  heuristicData,
  selectedPeriod,
  previousPeriod,
  setSelectedDataPoint,
  setThirdPaneOpen,
  previousData,
  currentPage,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate("/data-entry", {
      state: { pageId: location.pathname.split("/").pop() },
    });
  };

  if (!pageData || !pageData.datasets || pageData.datasets.length === 0) {
    return (
      <div className="error-message">
        No data not available.{" "}
        <button className="secondary" onClick={handleEditClick}>
          <FontAwesomeIcon icon={faEdit} /> Add Data
        </button>
      </div>
    );
  }

  const renderView = () => {
    switch (view) {
      case "overview":
        return (
          <SpiderChart
            data={pageData}
            title={title}
            pageId={pageId}
            size={chartSize}
            isHomePage={isHomePage}
            previousData={pageData.previousData}
            onDataPointClick={onDataPointClick}
          />
        );
      case "table":
        return (
          <TableView
            pages={pages}
            data={pageData}
            heuristics={heuristics}
            heuristicData={heuristicData}
            onDataPointClick={onDataPointClick}
            selectedPeriod={selectedPeriod}
            previousPeriod={previousPeriod}
            setSelectedDataPoint={setSelectedDataPoint}
            setThirdPaneOpen={setThirdPaneOpen}
          />
        );
      case "treemap":
        return (
          <TreeMapView
            pages={pages}
            data={pageData}
            heuristicData={heuristicData}
            previousData={previousData}
            selectedPeriod={selectedPeriod}
            onDataPointClick={onDataPointClick}
            setSelectedDataPoint={setSelectedDataPoint}
            setThirdPaneOpen={setThirdPaneOpen}
          />
        );

      case "notes":
        return (
          <NotesGrid
            pageId={pageId}
            data={pageData}
            currentPage={currentPage}
          />
        );
      case "links":
        return (
          <LinksGrid
            pageId={pageId}
            data={pageData}
            currentPage={currentPage}
          />
        );

      case "scoreTable":
        return (
          <ScoreTableView
            pages={pages}
            heuristicData={heuristicData}
            previousData={previousData}
            selectedPeriod={selectedPeriod}
            previousPeriod={previousPeriod}
            data={pageData}
            title={title}
            isHomePage={isHomePage}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={`page-view ${thirdPaneOpen ? "third-pane-open" : ""}`}>
      {renderView()}
    </div>
  );
};

export default PageView;
