import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import "./LinksGrid.css";

const heuristicLabels = {
  0: "Visibility of System Status",
  1: "Match Between System and Real World",
  2: "User Control and Freedom",
  3: "Consistency and Standards",
  4: "Error Prevention",
  5: "Recognition Rather Than Recall",
  6: "Flexibility and Efficiency of Use",
  7: "Aesthetic and Minimalist Design",
  8: "Help Users Recognize, Diagnose, and Recover from Errors",
  9: "Help and Documentation",
};

const LinksGrid = ({ data, pageId, selectedDataPoint, currentPage }) => {
  const [linksData, setLinksData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  console.log("data", data);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? faChevronUp : faChevronDown;
    }
    return faChevronUp;
  };

  useEffect(() => {
    const transformLinks = () => {
      try {
        setLoading(true);
        const linksList = [];

        if (currentPage === "Overview") {
          // Handle multiple datasets for Overview
          data?.datasets?.forEach((dataset) => {
            if (dataset?.links) {
              Object.entries(dataset.links).forEach(
                ([heuristicIndex, links]) => {
                  links.forEach((link) => {
                    linksList.push({
                      pageId: dataset.id,
                      pageName: dataset.label || "Unknown",
                      heuristicIndex,
                      heuristicName:
                        heuristicLabels[heuristicIndex] ||
                        `Heuristic ${heuristicIndex}`,
                      link,
                    });
                  });
                }
              );
            }
          });
        } else if (data?.datasets?.[0]?.links) {
          // Handle single dataset for specific page
          Object.entries(data.datasets[0].links).forEach(
            ([heuristicIndex, links]) => {
              links.forEach((link) => {
                linksList.push({
                  pageId,
                  pageName: data.datasets[0].label || "Unknown",
                  heuristicIndex,
                  heuristicName:
                    heuristicLabels[heuristicIndex] ||
                    `Heuristic ${heuristicIndex}`,
                  link,
                });
              });
            }
          );
        }

        setLinksData(linksList);
      } catch (err) {
        console.error("Error transforming links:", err);
        setError("Failed to process links data");
      } finally {
        setLoading(false);
      }
    };

    transformLinks();
  }, [data, pageId, currentPage, heuristicLabels]);

  const sortedLinksData = React.useMemo(() => {
    const sortableItems = [...linksData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        // Extract numbers from JIRA IDs for link sorting
        if (sortConfig.key === "link") {
          const numA = parseInt(a.link.split("-")[1]);
          const numB = parseInt(b.link.split("-")[1]);
          return sortConfig.direction === "ascending"
            ? numA - numB
            : numB - numA;
        }

        // Default string comparison for other columns
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [linksData, sortConfig]);

  if (loading) return <div className="loading">Loading links...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="jira-links-container">
      <div className="links-table-container scoreTable">
        <table className="links-table">
          <thead>
            <tr>
              <th
                onClick={() => requestSort("pageName")}
                className={`sortable ${sortConfig.key === "pageName" ? "sorted" : ""}`}
              >
                Product
                <FontAwesomeIcon
                  icon={getSortIcon("pageName")}
                  className="sort-icon"
                />
              </th>
              <th
                onClick={() => requestSort("heuristicIndex")}
                className={`sortable ${sortConfig.key === "heuristicIndex" ? "sorted" : ""}`}
              >
                Heuristic Index
                <FontAwesomeIcon
                  icon={getSortIcon("heuristicIndex")}
                  className="sort-icon"
                />
              </th>
              <th
                onClick={() => requestSort("link")}
                className={`sortable ${sortConfig.key === "link" ? "sorted" : ""}`}
              >
                Link
                <FontAwesomeIcon
                  icon={getSortIcon("link")}
                  className="sort-icon"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedLinksData.map((item, index) => (
              <tr key={index}>
                <td>{item.pageName}</td>
                <td>{item.heuristicName}</td>
                <td>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.link.split("/").pop()}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LinksGrid;
