// HeroSection.js
import React from "react";
import uselyImage from "./images/usely-screens.png";
import { Link } from "react-router-dom";
const HeroSection = () => (
  <section className="hero-section">
    <div className="hero-content">
      <h1>Finally, science meets art.</h1>
      <p>
        Usely revolutionizes performance tracking by seamlessly connecting your
        teams and monitoring key metrics and milestones over time. With the
        power of Usely AI, you can finally answer the question, "How are we
        performing?" with confidence.
      </p>
      <p>
        Whether you're tracking usability, CSTAT, NPI, or employee happiness.
        Gain real-time insights and drive continuous improvement across your
        organization with a platform that turns data into actionable success.
      </p>

      <button className="cta-button">
        <Link to="/signup" className="signup-btn">
          Start Tracking your performance now!
        </Link>
      </button>
    </div>
    <div className="hero-image">
      <img src={uselyImage} alt="Usely" />
    </div>
  </section>
);

export default HeroSection;
