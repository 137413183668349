import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "./firebase";
import NoteCard from "./NoteCard";
import NotesFilter from "./NotesFilter";
import { heuristics } from "./utils/commonUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faXmark,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import "./NotesGrid.css";

const NotesGrid = ({ data, pageId, selectedDataPoint, currentPage }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentFilter, setCurrentFilter] = useState("all");
  const [expandedNote, setExpandedNote] = useState(null);
  const [transformedNotes, setTransformedNotes] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const processNotes = () => {
      try {
        if (currentPage === "Overview") {
          // Handle multiple datasets for Overview
          const allNotes = [];
          data?.datasets?.forEach((dataset) => {
            const notesForDataset = Object.entries(dataset?.notes || {}).map(
              ([heuristicIndex, notes]) => ({
                productName: dataset.label,
                heuristicLabel: heuristics[heuristicIndex]?.label,
                heuristicIndex: parseInt(heuristicIndex),
                notes: notes,
              })
            );
            allNotes.push(...notesForDataset);
          });
          setTransformedNotes(allNotes);
        } else {
          // Handle single dataset for specific page
          const transformedNotes = Object.entries(
            data?.datasets[0]?.notes || {}
          ).map(([heuristicIndex, notes]) => ({
            productName: data?.datasets[0]?.label,
            heuristicLabel: heuristics[heuristicIndex]?.label,
            heuristicIndex: parseInt(heuristicIndex),
            notes: notes,
          }));
          setTransformedNotes(transformedNotes);
        }
      } catch (error) {
        console.error("Error processing notes:", error);
        setError("Failed to process notes data");
      }
    };

    processNotes();
  }, [data, currentPage, heuristics]);

  console.log("Data", currentPage);

  // Combined filter for search and heuristic selection
  const filteredNotes = transformedNotes.filter((noteGroup) => {
    if (!noteGroup.notes) return false;

    // Filter by heuristic
    const heuristicMatch =
      currentFilter === "all" ||
      `h${noteGroup.heuristicIndex + 1}` === currentFilter;

    // Filter by search term
    const searchMatch = noteGroup.notes.some(
      (note) =>
        note.toLowerCase().includes(searchTerm.toLowerCase()) ||
        noteGroup.heuristicLabel
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

    return heuristicMatch && (searchTerm === "" || searchMatch);
  });

  const truncateText = (text, maxLength = 150) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const getAllNotes = () => {
    return filteredNotes.flatMap((group) =>
      group.notes.map((note, i) => ({
        note,
        heuristicLabel: group.heuristicLabel,
        id: `${group.heuristicIndex}-${i}`,
      }))
    );
  };

  const handleNavigation = (direction) => {
    const allNotes = getAllNotes();
    const currentIndex = allNotes.findIndex((n) => n.id === expandedNote);

    let newIndex;
    if (direction === "next") {
      newIndex = currentIndex === allNotes.length - 1 ? 0 : currentIndex + 1;
    } else {
      newIndex = currentIndex === 0 ? allNotes.length - 1 : currentIndex - 1;
    }

    setExpandedNote(allNotes[newIndex].id);
  };

  return (
    <>
      <NotesFilter
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        currentFilter={currentFilter}
        onFilterChange={setCurrentFilter}
      />
      <div
        className={`notes-grid ${currentFilter !== "all" ? "filtered" : "all"}`}
      >
        {currentFilter !== "all" && (
          <h3 className="filtered-heading">
            "{heuristics[parseInt(currentFilter.slice(1)) - 1]?.label}" for{" "}
            {currentPage === "Overview" ? "All Products" : currentPage}
          </h3>
        )}
        {filteredNotes.length === 0 ? (
          <div className="note-card no-notes">
            <div className="note-header">
              <FontAwesomeIcon icon={faNoteSticky} />
              <span className="note-heuristic">Sorry, no notes found.</span>
            </div>
            <p className="note-content">
              Try refining your search and filters.
            </p>
          </div>
        ) : (
          filteredNotes.map((noteGroup, idx) => (
            <div key={idx} className="note-group">
              {currentFilter == "all" && (
                <h3 className="group-heading">{noteGroup.heuristicLabel}</h3>
              )}
              {noteGroup.notes.map((note, i) => (
                <NoteCard
                  key={i}
                  note={truncateText(note)}
                  heuristicLabel={noteGroup.heuristicLabel}
                  isExpanded={
                    expandedNote === `${noteGroup.heuristicIndex}-${i}`
                  }
                  onExpand={() =>
                    setExpandedNote(`${noteGroup.heuristicIndex}-${i}`)
                  }
                  fullNote={note}
                />
              ))}
            </div>
          ))
        )}
      </div>

      {expandedNote && (
        <div className="modal-overlay" onClick={() => setExpandedNote(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="nav-button prev"
              onClick={() => handleNavigation("prev")}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            <button
              className="nav-button next"
              onClick={() => handleNavigation("next")}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>

            <button
              className="close-button"
              onClick={() => setExpandedNote(null)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
            {filteredNotes.map((group) =>
              group.notes.map((note, noteIndex) => {
                const noteId = `${group.heuristicIndex}-${noteIndex}`;

                return (
                  noteId === expandedNote && (
                    <div key={noteId} className="note-expanded">
                      <h3>{group.heuristicLabel}</h3>
                      <div className="full-note">{note}</div>
                    </div>
                  )
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NotesGrid;
