import React, { useState, useEffect, useRef, useMemo } from "react";
import { db, isSandboxMode } from "./firebase";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faClipboardQuestion,
  faCompress,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import "./CoPilotChat.css";
import uselyLogo from "./images/usely-logo.svg"; // Import the SVG logo
import LoadingDots from "./components/LoadingDots";
import Chart from "chart.js/auto";

// Utility function to format Firestore timestamps.
const formatTimestamp = (timestamp) => {
  try {
    if (!timestamp) return "";

    // Handle Firestore timestamp object
    if (timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        month: "short",
        day: "numeric",
      }).format(date);
    }

    return "";
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "";
  }
};

const chartTheme = {
  colors: [
    "#ff8830",
    "#ff5951",
    "#ff227a",
    "#e500a8",
    "#d400b4",
    "#be00c0",
    "#a200cd",
    "#c300b2",
    "#d70098",
    "#e20081",
    "#e62e6e",
  ],
  font: {
    family:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
  },
};

const chartTypes = [
  { value: "bar", label: "Bar Chart" },
  { value: "line", label: "Line Chart" },
  { value: "pie", label: "Pie Chart" },
  { value: "polarArea", label: "Polar Chart" },
  { value: "bubble", label: "Bubble Chart" },
];

const MessageContent = ({ content }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartType, setChartType] = useState("bar");

  const createChart = (data, type) => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    let datasets = data.visualization.data.datasets;

    // Handle bubble chart data format
    if (type === "bubble") {
      datasets = datasets.map((dataset, index) => ({
        ...dataset,
        data: dataset.data.map((value, i) => ({
          x: value,
          y: value,
          r: Math.abs(value * 5),
        })),
        backgroundColor: dataset.data.map(
          (_, i) => chartTheme.colors[i % chartTheme.colors.length]
        ),
        borderColor: dataset.data.map(
          (_, i) => chartTheme.colors[i % chartTheme.colors.length]
        ),
      }));
    } else {
      // Single dataset - color each data point
      if (data.visualization.data.datasets.length === 1) {
        datasets = datasets.map((dataset, index) => ({
          ...dataset,
          backgroundColor: dataset.data.map(
            (_, i) => chartTheme.colors[i % chartTheme.colors.length]
          ),
          borderColor: dataset.data.map(
            (_, i) => chartTheme.colors[i % chartTheme.colors.length]
          ),
          borderWidth: 2,
        }));
      } else {
        // Multiple datasets - color each dataset
        datasets = datasets.map((dataset, index) => ({
          ...dataset,
          backgroundColor: chartTheme.colors[index % chartTheme.colors.length],
          borderColor: chartTheme.colors[index % chartTheme.colors.length],
          borderWidth: 2,
        }));
      }
    }

    chartInstance.current = new Chart(chartRef.current, {
      type: type,
      data: {
        ...data.visualization.data,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1,
        scales: {
          x: {
            display: type === "bubble" ? true : false,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.dataset.label || "";
                const title = data.visualization.data.labels[context.dataIndex];
                const value = context.raw;

                if (type === "bubble") {
                  return `${title}: ${value.y}`;
                } else if (["pie", "polarArea", "line", "bar"].includes(type)) {
                  return `${title}: ${value}`;
                }
                return context.dataset.label || "";
              },
            },
          },
          title: {
            display: true,
            text: data.visualization.title,
            font: chartTheme.font,
          },
          legend: {
            display: false,
            labels: {
              font: chartTheme.font,
            },
          },
        },
      },
    });
  };

  // Use useMemo to get parseHTMLToJSX from the parent scope.
  const { parseHTMLToJSX } = useMemo(
    () => ({
      parseHTMLToJSX: (content) => {
        const parseBoldText = (text) => {
          const parts = text.split(/(\*\*.*?\*\*)/);
          return parts.map((part, i) => {
            if (part.startsWith("**") && part.endsWith("**")) {
              return <strong key={i}>{part.slice(2, -2)}</strong>;
            }
            return part;
          });
        };

        const lines = content.split("\n").map((line, index) => {
          if (line.startsWith("### ")) {
            return <h3 key={index}>{parseBoldText(line.slice(4))}</h3>;
          } else if (line.startsWith("## ")) {
            return <h2 key={index}>{parseBoldText(line.slice(3))}</h2>;
          } else if (line.startsWith("# ")) {
            return <h1 key={index}>{parseBoldText(line.slice(2))}</h1>;
          } else if (line.startsWith("- ")) {
            return (
              <ul key={index}>
                <li>{parseBoldText(line.slice(2))}</li>
              </ul>
            );
          }
          return <p key={index}>{parseBoldText(line)}</p>;
        });

        return <>{lines}</>;
      },
    }),
    []
  );

  const renderContent = () => {
    try {
      // Split content by code blocks
      const parts = content.split(/(```[\s\S]*?```)/);
      return parts.map((part, index) => {
        // If part is a code block that starts with "```json"
        if (part.startsWith("```")) {
          const jsonMatch = part.match(/^```json\s*([\s\S]*?)\s*```$/i);
          if (jsonMatch) {
            try {
              const jsonData = JSON.parse(jsonMatch[1]);
              // If there's visualization data, render the chart container
              if (jsonData.visualization) {
                return (
                  <div key={index} className="co-pilot-chart-container">
                    <div className="chart-controls">
                      <select
                        value={chartType}
                        onChange={(e) => setChartType(e.target.value)}
                        className="chart-type-selector"
                      >
                        {chartTypes.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <canvas ref={chartRef} />
                  </div>
                );
              }
              // Otherwise, render the text from the JSON
              return <div key={index}>{parseHTMLToJSX(jsonData.text)}</div>;
            } catch (error) {
              console.error("Error parsing JSON code block:", error);
              // If error in parsing, show the block as preformatted text.
              return <pre key={index}>{part}</pre>;
            }
          } else {
            // If not a JSON code block, return it as preformatted text.
            return <pre key={index}>{part}</pre>;
          }
        }
        // Render any non-code block parts as normal text.
        return <div key={index}>{parseHTMLToJSX(part)}</div>;
      });
    } catch (error) {
      console.error("Error rendering message:", error);
      return parseHTMLToJSX(content);
    }
  };

  useEffect(() => {
    // This effect looks for a JSON code block to create the chart.
    try {
      const jsonMatch = content.match(/```json\s*([\s\S]*?)\s*```/i);
      if (jsonMatch) {
        const data = JSON.parse(jsonMatch[1]);
        if (data.visualization && chartRef.current) {
          createChart(data, chartType);
        }
      }
    } catch (error) {
      console.error("Error creating chart:", error);
    }
  }, [content, chartType]);

  return <div className="message-content">{renderContent()}</div>;
};

const CoPilotChat = ({ onClose }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [error, setError] = useState(null); // Track errors
  const [loading, setLoading] = useState(false);
  const defaultPrompts = [
    "Tell me what areas to focus on usability issues.",
    "What are the highest scoring products?",
    "What are the lowest scoring products?",
    "Chart the top product scores.",
    "Chart the lowest product scores.",
    "What are the key usability issues?",
  ];

  const auth = getAuth();
  const currentUserId = auth.currentUser?.uid;
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchPreviousDiscussions = async (currentUserId) => {
      if (!currentUserId) {
        console.error("Invalid currentUserId:", currentUserId);
        setError("Invalid user ID.");
        return;
      }

      try {
        const sessionRef = collection(db, "ai_sessions");
        const q = query(
          sessionRef,
          where("currentUserId", "==", currentUserId)
        );
        const sessionSnapshot = await getDocs(q);
        const previousMessages = sessionSnapshot.docs
          .map((doc) => doc.data().messages)
          .flat();
        setMessages(previousMessages);
      } catch (err) {
        console.error("Error fetching previous discussions:", err.message);
        setError("Failed to load previous discussions.");
      }
    };

    if (currentUserId) {
      fetchPreviousDiscussions(currentUserId);
    }

    const welcomeMessage = {
      role: "assistant",
      content:
        "Hey there! 👋 I'm Usely, your AI assistant. I'm here to help you dive into usability tracking and share all the exciting progress we’re making. Don’t hesitate to ask me anything—let's chat!",
    };
    setMessages((prev) => [...prev, welcomeMessage]);
  }, [currentUserId]);

  const API_URL_SANDBOX =
    process.env.NODE_ENV === "production"
      ? "https://us-central1-uselyapp-sandbox.cloudfunctions.net/generateResponse"
      : "http://127.0.0.1:5001/uselyapp-sandbox/us-central1/generateResponse";

  const API_URL_PROD =
    process.env.NODE_ENV === "production"
      ? "https://us-central1-sprinklr-use-framework.cloudfunctions.net/generateResponse"
      : "http://127.0.0.1:5001/sprinklr-use-framework/us-central1/generateResponse";

  const API_ENV = isSandboxMode() ? API_URL_SANDBOX : API_URL_PROD;

  const parseHTMLToJSX = (content) => {
    const parseBoldText = (text) => {
      const parts = text.split(/(\*\*.*?\*\*)/);
      return parts.map((part, i) => {
        if (part.startsWith("**") && part.endsWith("**")) {
          return <strong key={i}>{part.slice(2, -2)}</strong>;
        }
        return part;
      });
    };

    const lines = content.split("\n").map((line, index) => {
      if (line.startsWith("### ")) {
        return <h3 key={index}>{parseBoldText(line.slice(4))}</h3>;
      } else if (line.startsWith("## ")) {
        return <h2 key={index}>{parseBoldText(line.slice(3))}</h2>;
      } else if (line.startsWith("# ")) {
        return <h1 key={index}>{parseBoldText(line.slice(2))}</h1>;
      } else if (line.startsWith("- ")) {
        return (
          <ul key={index}>
            {line
              .split("- ")
              .filter(Boolean)
              .map((item, i) => (
                <li key={i}>{parseBoldText(item.trim())}</li>
              ))}
          </ul>
        );
      } else {
        return <p key={index}>{parseBoldText(line)}</p>;
      }
    });
    return lines;
  };

  const renderVisualization = (vizData) => {
    if (chartRef.current) {
      new Chart(chartRef.current, {
        type: vizData.type,
        data: vizData.data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: vizData.title,
            },
          },
        },
      });
    }
  };

  const parseMessage = (content) => {
    try {
      // Check if content contains a JSON code block.
      if (content.includes("{") && content.includes("}")) {
        const jsonMatch = content.match(/```json\s*([\s\S]*?)\s*```/i);
        if (jsonMatch) {
          const jsonData = JSON.parse(jsonMatch[1]);
          return (
            <div className="message-content">
              <p>{jsonData.text}</p>
              {jsonData.visualization && (
                <ChartComponent
                  type={jsonData.visualization.type}
                  data={jsonData.visualization.data}
                  title={jsonData.visualization.title}
                />
              )}
            </div>
          );
        }
      }
      // If not JSON, return plain text.
      return <p>{content}</p>;
    } catch (error) {
      console.error("Error parsing message:", error);
      return <p>{content}</p>;
    }
  };

  const ChartComponent = ({ type, data, title }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
      if (chartRef.current) {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
        chartInstance.current = new Chart(chartRef.current, {
          type,
          data,
          options: {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: title,
              },
            },
          },
        });
      }
      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }, [type, data, title]);

    return (
      <div className="co-pilot-chart-container">
        <canvas ref={chartRef} />
      </div>
    );
  };

  const sendMessage = async (messageContent) => {
    const timestamp = Timestamp.now();
    const userMessage = {
      role: "user",
      content: messageContent,
      userMessageTimestamp: timestamp,
    };

    // Add user message immediately.
    setMessages((prev) => [...prev, userMessage]);

    // Add loading message.
    const loadingMessage = {
      role: "assistant",
      content: "",
      isLoading: true,
      userMessageTimestamp: timestamp,
    };

    setMessages((prev) => [...prev, loadingMessage]);
    setInput("");
    setLoading(true);

    try {
      const response = await fetch(API_ENV, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          message: messageContent.trim(),
          currentUserId,
          userMessageTimestamp: timestamp,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error?.message || "An unexpected error occurred."
        );
      }
      const { content } = await response.json();
      const botMessage = { role: "assistant", content };
      // Replace loading message with the real response.
      setMessages((prev) =>
        prev.map((msg) =>
          msg.isLoading
            ? { ...botMessage, userMessageTimestamp: timestamp }
            : msg
        )
      );
    } catch (err) {
      console.error("Error fetching response:", err.message);
      // Remove loading message on error.
      setMessages((prev) => prev.filter((msg) => !msg.isLoading));
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="co-pilot-chat">
      <div className="chat-header">
        <h3>
          Usely AI <span>(powered by Chat GPT 4.o)</span>
        </h3>
        <button onClick={onClose} className="close-chat-button">
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>

      <div className="chat-messages">
        {messages
          .filter((msg) => msg.role)
          .sort((a, b) => {
            const timeA = a.userMessageTimestamp?.seconds || 0;
            const timeB = b.userMessageTimestamp?.seconds || 0;
            if (timeA !== timeB) {
              return timeA - timeB;
            }
            const nanoA = a.userMessageTimestamp?.nanoseconds || 0;
            const nanoB = b.userMessageTimestamp?.nanoseconds || 0;
            return nanoA - nanoB;
          })
          .toReversed()
          .map((msg, i) => (
            <div key={i} className={`message ${msg.role}`}>
              {msg.isLoading ? (
                <LoadingDots />
              ) : msg.role === "assistant" ? (
                <div className="assistant-message">
                  <img src={uselyLogo} alt="Usely" className="logo" />
                  <div className="assistant-message-body">
                    <MessageContent content={msg.content} />
                  </div>
                </div>
              ) : (
                <p>
                  {msg.content}
                  <span className="timestamp">
                    {formatTimestamp(msg.userMessageTimestamp)}
                  </span>
                </p>
              )}
            </div>
          ))}
        <div className="default-prompts">
          {defaultPrompts.map((prompt, index) => (
            <button
              key={index}
              onClick={() => {
                setInput(prompt);
                sendMessage(prompt);
              }}
              className="prompt-button"
            >
              {prompt}
            </button>
          ))}
        </div>
        <div className="welcome-message">
          Hey there! 👋 I'm Usely, your AI assistant. I'm here to help you dive
          into usability tracking and share all the exciting progress we’re
          making. Don't hesitate to ask me anything—let's chat!
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={
            loading ? "Awesome question! Hang tight..." : "Ask me anything..."
          }
          disabled={loading}
        />
        <button onClick={() => sendMessage(input)} disabled={loading}>
          {loading ? "Looking..." : "Send"}
        </button>
      </div>
    </div>
  );
};

export default CoPilotChat;
//working copy 2
