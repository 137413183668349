import React from "react";
import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import LanguageSelection from "./LanguageSelection";
import ContentSection from "./ContentSection";
import Method from "./Method";
import FeatureBreakOut from "./FeatureBreakOut";
import TestimonialsSection from "./TestimonialsSection";
import JoinUsSection from "./JoinUsSection";
import Footer from "./Footer";
import "./HomePage.css";

const HomePage = () => (
  <>
    <Navbar />
    <main className="home">
      {/* <ContentSection /> */}
      <Method />
      <FeatureBreakOut />
      <TestimonialsSection />
      <HeroSection />
      {/* <JoinUsSection /> */}
    </main>
    <Footer />
  </>
);

export default HomePage;
