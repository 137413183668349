import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCodeCompare,
  faPenToSquare,
  faUser,
  faSignOutAlt,
  faEllipsisV,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faEdit,
  faTrash,
  faChevronRight,
  faChevronDown,
  faEyeDropper,
  faGripVertical,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

import { db } from "./firebase";
import uselyLogo from "./images/usely-logo.svg";
import {
  doc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
  deleteField,
  writeBatch,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDoc } from "firebase/firestore";

import { HexColorPicker, HexColorInput } from "react-colorful";
import { createPortal } from "react-dom";
import debounce from "lodash/debounce";

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  pages,
  logout,
  userInfo,
  currentUser,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [expandedPages, setExpandedPages] = useState({});
  const menuRef = useRef(null);
  const [editingPageId, setEditingPageId] = useState(null);
  const [pageName, setPageName] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [currentColor, setCurrentColor] = useState("");
  const [colorPickerPosition, setColorPickerPosition] = useState({
    x: 100,
    y: 100,
  });
  const dragStartPosition = useRef({ x: 0, y: 0 });
  const navigate = useNavigate();
  const pickerRef = useRef(null);
  const dragRef = useRef(null);
  const location = useLocation();
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isResearcher, setIsResearcher] = useState(false);

  const isAdmin = userInfo?.isAdmin || false;
  const isResearcher = userInfo?.isResearcher || false;
  const auth = getAuth();

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const handleEditPage = (page) => {
    setEditingPageId(page.id);
    setPageName(page.title);
  };

  const savePageName = async (page) => {
    if (pageName !== page.title) {
      const pageRef = doc(db, "pages", page.id);
      await updateDoc(pageRef, { title: pageName });

      // Update the label in the releases collection
      const releasesSnapshot = await getDocs(collection(db, "releases"));
      for (const releaseDoc of releasesSnapshot.docs) {
        const releaseData = releaseDoc.data();

        // Check if the release data contains the page ID
        if (releaseData[page.id]) {
          const pageData = releaseData[page.id];
          const datasets = pageData.datasets;

          // Update the label in the datasets array
          const updatedDatasets = datasets.map((dataset) => {
            return { ...dataset, label: pageName };
          });

          // Update the datasets array in the Firestore document
          await updateDoc(doc(db, "releases", releaseDoc.id), {
            [`${page.id}.datasets`]: updatedDatasets,
          });
        }
      }
    }
    setEditingPageId(null);
  };

  const handleDeletePage = async (pageId, pageTitle) => {
    // Add a confirmation dialog
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the page "${pageTitle}"? This action cannot be undone.`
    );

    if (!isConfirmed) {
      return; // If the user cancels, exit the function
    }

    try {
      // Delete the page document from the 'pages' collection
      const pageRef = doc(db, "pages", pageId);
      await deleteDoc(pageRef);

      // Retrieve all documents from the 'releases' collection
      const releasesSnapshot = await getDocs(collection(db, "releases"));

      // Iterate over each release document
      for (const releaseDoc of releasesSnapshot.docs) {
        const releaseData = releaseDoc.data();

        // Check if the release contains the page ID
        if (releaseData[pageId]) {
          const releaseRef = doc(db, "releases", releaseDoc.id);

          // Delete the field corresponding to the page ID
          await updateDoc(releaseRef, {
            [pageId]: deleteField(),
          });
        }
      }

      console.log(`Page ${pageId} and its data in releases have been deleted.`);
      navigate("/home");
    } catch (error) {
      console.error("Error deleting page and its data from releases:", error);
      // Optionally, show an error message to the user
      alert("An error occurred while deleting the page. Please try again.");
    }
  };

  const handleKeyDown = (e, page) => {
    if (e.key === "Enter") {
      savePageName(page);
    }
  };

  const handleBlur = (page) => {
    savePageName(page);
  };

  const togglePageExpansion = (pageId) => {
    setExpandedPages((prevExpandedPages) => ({
      ...prevExpandedPages,
      [pageId]: !prevExpandedPages[pageId],
    }));
  };

  const handleColorUpdate = async (pageId, newColor) => {
    try {
      const batch = writeBatch(db);

      // Update page color in pages collection
      const pageRef = doc(db, "pages", pageId);
      batch.update(pageRef, { color: newColor });

      // Update color in releases while preserving data structure
      const releasesSnapshot = await getDocs(collection(db, "releases"));
      releasesSnapshot.forEach((releaseDoc) => {
        const releaseRef = doc(db, "releases", releaseDoc.id);
        const releaseData = releaseDoc.data();
        const pageData = releaseData[pageId];

        if (pageData?.datasets?.[0]) {
          // Create new dataset that preserves all existing data
          const updatedDataset = {
            ...pageData,
            datasets: pageData.datasets.map((dataset) => ({
              ...dataset,
              borderColor: newColor,
              backgroundColor: newColor + "33",
            })),
          };

          // Update only this page's data
          batch.update(releaseRef, {
            [pageId]: updatedDataset,
          });
        }
      });

      await batch.commit();
      setShowColorPicker(null);
    } catch (error) {
      console.error("Error updating color:", error);
      alert("Error updating color");
    }
  };

  const MemoizedColorPicker = memo(({ color, onChange }) => (
    <HexColorPicker color={color} onChange={onChange} />
  ));

  const ColorPickerPortal = ({ pageId, initialColor, onSave, onClose }) => {
    const pickerRef = useRef(null);
    const [currentColor, setCurrentColor] = useState(initialColor);

    // Debounce the color update
    const debouncedSetSelectedColor = useCallback(
      debounce((color) => setCurrentColor(color), 300),
      []
    );

    const handleMouseDown = (e) => {
      e.preventDefault();
      if (!pickerRef.current) return;

      const rect = pickerRef.current.getBoundingClientRect();
      dragStartPosition.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };

      const handleMouseMove = (e) => {
        e.preventDefault();
        setColorPickerPosition({
          x: e.clientX - dragStartPosition.current.x,
          y: e.clientY - dragStartPosition.current.y,
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    return createPortal(
      <div
        ref={pickerRef}
        className="color-picker-modal"
        style={{
          position: "fixed",
          left: colorPickerPosition.x,
          top: colorPickerPosition.y,
          transform: "translate(0, 0)",
          willChange: "transform",
        }}
      >
        <div className="picker-header" onMouseDown={handleMouseDown}>
          <FontAwesomeIcon icon={faGripVertical} className="drag-icon" />
          <button className="close-btn" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="picker-content" onClick={(e) => e.stopPropagation()}>
          <MemoizedColorPicker
            color={currentColor}
            onChange={debouncedSetSelectedColor}
          />
          <div className="color-input-row">
            <span>#</span>
            <HexColorInput
              prefixed={false}
              color={currentColor}
              onChange={debouncedSetSelectedColor}
            />
            <button onClick={() => onSave(pageId, currentColor)}>Save</button>
          </div>
        </div>
      </div>,
      document.body
    );
  };

  const renderPages = (pages, parentId = null) => {
    if (!pages || pages.length === 0) {
      console.warn("No pages found. Please ensure pages are loaded correctly.");
      return <p>No pages available.</p>;
    }

    const filteredPages = pages.filter((page) => {
      return parentId == null ? !page.parentPage : page.parentPage === parentId;
    });
    if (filteredPages.length === 0) {
      console.warn(`No child pages found for parentId: ${parentId}`);
    }

    return filteredPages.map((page) => (
      <li key={page.id} className="page-item">
        <div className="page-header">
          {editingPageId === page.id ? (
            <input
              type="text"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, page)}
              onBlur={() => handleBlur(page)}
              autoFocus
            />
          ) : (
            <>
              {pages.some((p) => p.parentPage === page.id) && (
                <div className="menu-toggle">
                  <FontAwesomeIcon
                    icon={
                      expandedPages[page.id] ? faChevronDown : faChevronRight
                    }
                    onClick={() => togglePageExpansion(page.id)}
                    className="chevron-icon"
                    style={{ color: "#777777" }}
                  />
                </div>
              )}
              <NavLink
                to={getNavLinkWithView(`/page/${page.id}`)}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {page.title}
              </NavLink>
              {(isAdmin || isResearcher) && (
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="edit-icon"
                    onClick={() => handleEditPage(page)}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="delete-icon"
                    onClick={() => handleDeletePage(page.id, page.title)}
                  />
                  <button
                    className="color-picker-trigger"
                    onClick={() => {
                      setShowColorPicker(page.id);
                      setSelectedColor(page.color);
                      setColorPickerPosition({ x: 100, y: 100 }); // Initial position
                    }}
                  >
                    <FontAwesomeIcon icon={faEyeDropper} />
                  </button>
                  {showColorPicker === page.id && (
                    <ColorPickerPortal
                      show={showColorPicker === page.id}
                      pageId={page.id}
                      initialColor={selectedColor}
                      onSave={handleColorUpdate}
                      onClose={() => setShowColorPicker(null)}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {expandedPages[page.id] && (
          <ul className="nested-pages">
            {renderPages(pages, page.id)}
            {/* Add "New Child Page" link under each expanded page */}
            {(isAdmin || isResearcher) && (
              <li className="add-new-child">
                <NavLink
                  to={`/add-page?parent=${page.id}`}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <FontAwesomeIcon icon={faPlus} /> New...
                </NavLink>
              </li>
            )}
          </ul>
        )}
      </li>
    ));
  };

  useEffect(() => {
    if (!pages || pages.length === 0) {
      console.warn("No pages found. Please ensure pages are loaded correctly.");
    }
  }, [pages]);

  const getNavLinkWithView = (to) => {
    // Don't add view parameter for compare page
    if (to === "/compare") {
      return to;
    }

    // Get current view from URL or default to overview
    const currentUrl = new URL(window.location.href);
    const currentView = currentUrl.searchParams.get("view") || "overview";

    // Return new path with current view maintained
    return `${to}?view=${currentView}`;
  };

  useEffect(() => {
    // Only run on /home path without any parameters
    if (window.location.pathname === "/home" && !window.location.search) {
      const newUrl = `${window.location.pathname}?view=overview`;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);

  useEffect(() => {
    if (showColorPicker) {
      setShowColorPicker(null);
      setSelectedColor(null);
      setCurrentColor(null);
      setColorPickerPosition({ x: 100, y: 100 });
    }
  }, [location]); // Watch for location changes

  return (
    <div className="sidebar">
      <button
        className="sidebar-toggle"
        onClick={() => setSidebarOpen(!sidebarOpen)}
        aria-label={sidebarOpen ? "Close sidebar" : "Open sidebar"}
      >
        <FontAwesomeIcon
          icon={
            sidebarOpen
              ? faDownLeftAndUpRightToCenter
              : faUpRightAndDownLeftFromCenter
          }
        />
      </button>

      <Link to="/home?view=overview" className="logoContain">
        <img src={uselyLogo} alt="Usely" className="logo" />
      </Link>
      <nav>
        <ul>
          {currentUser ? (
            <>
              {/* <li className="nav-header">Favorites</li>
              <li>
                <NavLink>Conversational Analytics</NavLink>
              </li>
              <li className="nav-header">Answers</li>
              <li>
                <NavLink>What is my top products...</NavLink>
              </li>
              <li>
                <NavLink>Where are my usablity...</NavLink>
              </li> */}
              <li className="nav-header">My Folders</li>
              <li>
                <NavLink
                  to={getNavLinkWithView("/home")}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Overview
                </NavLink>
              </li>
              {renderPages(pages)}

              {(isAdmin || isResearcher) && (
                <li>
                  <NavLink
                    to="/add-page"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <FontAwesomeIcon icon={faPlus} /> New...
                  </NavLink>
                </li>
              )}

              <li className="nav-header">Reports</li>

              <li>
                <NavLink
                  to="/compare"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <FontAwesomeIcon icon={faCodeCompare} /> Compare Products
                </NavLink>
              </li>

              {(isAdmin || isResearcher) && (
                <>
                  <li className="nav-header">Settings</li>
                  <li>
                    <NavLink
                      to={getNavLinkWithView("/data-entry")}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} /> Update Scores
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/add-page"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add New Page
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/add-release"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add New Release
                    </NavLink>
                  </li>
                  {isAdmin && (
                    <li>
                      <NavLink
                        to="/user-approval"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <FontAwesomeIcon icon={faUserShield} /> User Approval
                      </NavLink>
                    </li>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <li>
                <NavLink
                  to="/auth"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Login
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/signup"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Sign Up
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      {userInfo && (
        <div className="user-info">
          {userInfo.photoURL ? (
            <img
              src={userInfo.photoURL}
              alt="User avatar"
              className="user-avatar"
            />
          ) : (
            <FontAwesomeIcon icon={faUser} className="user-avatar-icon" />
          )}
          <span className="user-name">
            {(userInfo.displayName || "User").replace(/\s*\(.*?\)\s*/g, "")}
          </span>
          <div className="more-icon-container" ref={menuRef}>
            <div className="more-icon" onClick={toggleMenu}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            {menuOpen && (
              <div className="flyout-menu">
                <button onClick={logout} className="logout-button">
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </button>
                {/* Add more menu items here if needed */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
